<template>
 <div>
     <v-snackbar
             v-model="model"
             :timeout="delay"
     >
         {{ result["message"] }}
     </v-snackbar>
 </div>
</template>
<script>
export default {
  props: ["result"],
  data() {
    return {
      model: true,
    };
  },
  computed: {
    noticeType() {
      let noticeType = "error";
      if (typeof this.result.noticeType != "undefined") {
        noticeType = this.result.noticeType;
      }
      return noticeType;
    },
    delay() {
      let delay = 3000;
      if (typeof this.result.delay != "undefined") {
        delay = this.result.delay;
      }
      return delay;
    },
  },
  created() {
    if (this.result.message != "you do not have permission to view this page") {
      if (
        typeof this.result.redirect != "undefined" ||
        typeof this.result.delayedRedirect != "undefined"
      ) {
        let caller = this;
        setTimeout(() => {
          this.alert = false;
          if (caller.$route.path == caller.result.redirect) {
            caller.$router.go();
          } else {
            caller.$router.push(caller.result.redirect).catch(() => {
              caller.$router.go();
            });
          }
        }, this.delay);
      }
    }
  },
};
</script>
